const links = [
  {
    label: 'Home',
    url: '/'
  },
  // {
  //   label: 'Projetos',
  //   url: '/projects/'
  // },
  {
    label: 'Sobre',
    url: '/about/'
  },
]

export default links
