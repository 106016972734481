import { Github } from '@styled-icons/boxicons-logos/Github';
import { Twitter } from '@styled-icons/boxicons-logos/Twitter';
import { Instagram } from '@styled-icons/boxicons-logos/Instagram';

const Icons = {
  Github,
  Twitter,
  Instagram
}

export default Icons;
