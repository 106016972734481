const links = [
  {
    label: 'Github',
    url: 'https://github.com/dutradotdev'
  },
  {
    label: 'Twitter',
    url: 'https://twitter.com/dutradotdev'
  },
  {
    label: 'Instagram',
    url: 'https://instagram.com/dutra.dev'
  },
]

export default links;
