import styled from 'styled-components'

export const SocialLinksWrapper = styled.nav`
  margin: 2rem auto;
  width: 100%;
  justify-content: center;
`
export const SocialLinksList = styled.ul`
  align-items: center;
  display: flex;
  justify-content: center;
  list-style: none;
  background-color: var(--background);
`

export const SocialLinksLink = styled.a`
  color: var(--texts);
  text-decoration: none;
  transition: color 0.5s;
  &:hover {
    color: var(--highlight);
  }
`
export const SocialLinksItem = styled.li`
  padding-right: 1rem;
`

export const IconWrapper = styled.li`
  fill: #bbb;
  width: 30px;
  height: 30px;
`
