import React from 'react'
import PropTypes from 'prop-types'
import GlobalStyles from '../../styles/global'
import MenuLinks from '../../components/MenuLinks'
import SocialLinks from '../../components/SocialLinks'
import * as S from './styled';

const Layout = ({ children, showSocialLinks = true }) => {
  return (
    <S.LayoutWrapper>
      <GlobalStyles />
      <MenuLinks />
      <S.LayoutMain showSocialLinks={showSocialLinks}>{children}</S.LayoutMain>
      {showSocialLinks && <SocialLinks />}
    </S.LayoutWrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
