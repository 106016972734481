import React, { useState, useEffect } from 'react'
import { Bulb as Light } from '@styled-icons/boxicons-regular/Bulb'
import links from './content'
import * as S from './styled'
import getThemeColor from '../../utils/getThemeColor'

const MenuLinks = () => {
  const [theme, setTheme] = useState(null)

  const isDarkMode = theme === 'dark'

  useEffect(() => {
    setTheme(window.__theme)

    window.__onThemeChange = () => setTheme(window.__theme)
  }, [])

  return (
    <S.MenuLinksWrapper>
      <S.MenuLinksList>
        {links.map((link, item) => (
          <S.MenuLinksItem key={item}>
            <S.MenuLinksLink 
              cover
              direction="left"
              duration="0.6"
              bg={getThemeColor()}
              to={link.url}
              activeClassName="active"
            >
              {link.label}
            </S.MenuLinksLink>
          </S.MenuLinksItem>
          ))}
        <S.LightIcon>
          <Light 
            onClick={() => {
              window.__setPreferredTheme(isDarkMode ? 'light' : 'dark')
            }}
            className={theme}
          />
        </S.LightIcon>
      </S.MenuLinksList>
    </S.MenuLinksWrapper>
  )
}

export default MenuLinks;
