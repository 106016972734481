import styled from 'styled-components'

export const LayoutWrapper = styled.section`
  display: flex;
  flex-direction: column;
  background: var(--background);
`

export const LayoutMain = styled.main`  
  background: var(--background);
  min-height: ${props => props.showSocialLinks ? '90vh' : '100vh'};
  padding: 1rem;
`
