import media from 'styled-media-query'
import styled from 'styled-components'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

export const MenuLinksWrapper = styled.nav`
  width: 100%;
  background-color: var(--background);
  align-self: center;
  position: fixed;
  z-index: 99;
  box-shadow: 0px 1px 11px -5px rgba(0,0,0,0.75);
`
//border-bottom: 0.5px solid var(--borders);
export const MenuLinksList = styled.ul`
  display: flex;  
  flex-direction: row;
  font-size: 1.2rem;
  font-weight: 300;
  text-align: center;

  ${media.lessThan('large')`
    width: 100%;
  `}
`

export const MenuLinksItem = styled.li`
  display: flex;
  flex: 1;
  justify-content: center;  
  padding: .5rem 0;
  margin-right: 1rem;
  align-items: center;
  .active {
    color: var(--highlight);
  }

  ${media.lessThan('large')`
    flex: 3;
    margin-right: 0;
  `}
`

export const MenuLinksLink = styled(AniLink)`
  color: var(--texts);
  text-decoration: none;
  transition: color 0.5s;

  &:hover {
    color: var(--highlight);
  }
`

export const LightIcon = styled(MenuLinksItem)`
  display: initial;
  color: var(--texts);

  & svg {
    height: 2rem;
    width: 2rem;
  }

  ${media.lessThan('large')`
    flex: 1;

    & svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  `}
`
